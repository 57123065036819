<template>
  <div class="back-view" @click="onClick">
    <a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.juheba.app">链接应用宝</a>
    <br/>
    <br/>
    <div  @click="onConfirm">windowOpen</div>
  </div>
</template>

<script>
import prompt from '@/components/prompt'
export default {
  name: "register",
  components: {
    prompt
  },
  data() {
    return {
      isCheck: false,
      showPrompt: false,
      checkImgArr: [
        require("./images/icon_check_default.svg"),
        require("./images/icon_checked.svg"),
      ],

      inviteInfo: {
        packageName: '',
        redCode: '',
        appName: ''
      },
      phone: "",
      smsCode: "",
      pwd: "",
      confirmPwd: "",
      downloadUrl: '',// app下载地址
      agreementData: {
        userAgreement: '',
        secrecyAgreement: ''
      }
    };
  },
  created() {
    this.inviteInfo = this.$route.query;
    this.inviteInfo.appName = decodeURIComponent(this.$route.query.appName);
    this.loadAgreement()
  },
  computed: {
    complete() {
      return this.phone && this.smsCode && this.pwd && this.confirmPwd && this.isCheck;
    },
    isIos() {
      const u = navigator.userAgent;
      const isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
      return isIos
    }
  },
  methods: {
    
    onConfirm() {
      var oA = document.createElement("a"); //创建a标签
      oA.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.juheba.app'; //添加 href 属性
      oA.click(); //模拟点击
    },
    checkProtocol() {
      this.isCheck = !this.isCheck;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/_mixins.scss";
.bg {
  padding: 24px 32px;
  background-color: #f3f5f9;

  .title {
    color: #313235;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 6px;
  }

  .rec-view {
    margin-top: 32px;
    padding: 0 16px;
    background-color: #fff;
    border-radius: 6px;
    height: 48px;

    .rec-tip {
      color: #313235;
      font-size: 14px;
      font-weight: 500;
    }

    .rec-line {
      width: 1px;
      height: 22px;
      margin-left: 16px;
      margin-right: 12px;
      background-color: #e2e4ec;
    }

    .rec-name {
      color: #313235;
      font-size: 14px;
    }
  }

  .input-area {
    margin-top: 12px;
    font-size: 14px;
    color: #93949d;
    border-radius: 6px;
    overflow: hidden;
    line-height: 52px;

    .code-line {
      width: 1px;
      height: 22px;
      margin-right: 16px;
      background-color: #e2e4ec;
    }
  }

  .pwd-rule {
    margin-top: 12px;
    color: #93949d;
    font-size: 12px;
    font-weight: 500;
  }

  .van-button {
    margin-top: 40px;
    border-radius: 6px;
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
  }

  .gray {
    background-color: #e2e4ec;
    color: #93949d;
  }

  .blue {
    background-color: #2e5bfd;
    color: #fff;
  }

  .footer {
    position: fixed;
    bottom: 36px;
    left: 0;
    right: 0;
    font-size: 12px;
    @include safeArea(padding-bottom);
    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
</style>